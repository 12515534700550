import React from 'react'
import { graphql } from 'gatsby'

import enMessages from '../i18n/accommodation.en.json'
import plMessages from '../i18n/accommodation.pl.json'
import itMessages from '../i18n/accommodation.it.json'

import enMessagesK from '../i18n/accommodation-k.en.json'
import plMessagesK from '../i18n/accommodation-k.pl.json'
import itMessagesK from '../i18n/accommodation-k.it.json'

import Layout from '../components/layout'
import SingleHeader from '../components/single-header'
import Footer from '../components/footer'

const messages = {
  en: enMessages,
  pl: plMessages,
  it: itMessages,
};

const messagesk = {
  en: enMessagesK,
  pl: plMessagesK,
  it: itMessagesK,
}

const iframe = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  border: 0,
  saturation: '-90',
  border: 0,
  };


const AccommodationPage = ({ pageContext: { locale }, data }) => (
  <Layout locale={locale}>
    <SingleHeader
      locale={locale}
      img={data.locationImage}
      title={messages[locale].title}
    />

    <div className="page_content">
      <div className="full_width_centered half">
        <div style={{width: '100%', padding: '0 16px'}}>
          <h2>SYCÓW AROMA SPA</h2>
          <p dangerouslySetInnerHTML={{ __html: messages[locale].description }} />
          <p dangerouslySetInnerHTML={{ __html: messages[locale].address }} />
          <p dangerouslySetInnerHTML={{ __html: messages[locale].website }} />


          <div class="map">
                <iframe
                  style={iframe}
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9977.599800646703!2d17.7552652!3d51.3037657!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x591f7ab4d997577f!2sAroma+Stone+Hotel+Spa!5e0!3m2!1sen!2sie!4v1544277570463"
    width="100%"
    height="450"
    frameBorder="0"
    allowFullScreen
      />
    </div>
            </div>

        <div style={{width: '100%', padding: '0 16px'}}>
            <h2>Hotel Kasprzak</h2>

            <p dangerouslySetInnerHTML={{ __html: messagesk[locale].description }} />
  <p dangerouslySetInnerHTML={{ __html: messagesk[locale].bus }} />
  <p dangerouslySetInnerHTML={{ __html: messagesk[locale].address }} />
  <p></p>


  <div class="map">
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9956.82695799699!2d17.6671023!3d51.3992559!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x44d79ff179ebc648!2sHotel+Kasprzak!5e0!3m2!1sen!2sie!4v1554247964987!5m2!1sen!2sie"
width="100%"
height="450"
frameBorder="0"
style={iframe}
allowFullScreen
  />
  </div>
        </div>

      </div>
    </div>
    <Footer locale={locale} />
  </Layout>
)

export default AccommodationPage

export const pageQuery = graphql`
  query {
    locationImage: file(
      relativePath: { eq: "accommodation/accommodation.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, maxHeight: 369, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
